export function usePromise(store, key) {
  if (!store || !key) return console.warn('usePromise: state and key are required')

  const init = () => {
    store[key] = {}
    store[key].promise = new Promise((resolve, reject) => {
      store[key].resolve = resolve
      store[key].reject = reject
    })
  }
  const get = () => store[key].promise
  const resolve = (data) => store[key].resolve(data)
  const reject = (err) => store[key].reject(err)

  return {
    get,
    init,
    resolve,
    reject
  }
}

// 气泡展示 promise
export function usePopoverPromise(store) {
  return usePromise(store, 'usePopoverPromise')
}
