/**
 * 获取免邮活动信息的变化
 * @param {*} newValue 当前免邮活动信息
 * @param {*} oldValue 旧的免邮活动信息
 * @returns 
 */
export function getFreeShippingChange(newValue, oldValue) {
  const {
    activityId: newActivityId,
    activityType: newActivityType,
    shippingMethodName: newShippingMethodName,
    nowLevel: newNowLevel,
  } = newValue
  const {
    activityId: oldActivityId,
    activityType: oldActivityType,
    shippingMethodName: oldShippingMethodName,
    nowLevel: oldNowLevel,
  } = oldValue
  // 活动是否变化
  const isChange = newActivityId !== oldActivityId || newActivityType !== oldActivityType || newShippingMethodName !== oldShippingMethodName
  // 是否是阶梯变化（运费活动档位变化）
  const isFreightStageChange = newNowLevel != oldNowLevel
  return {
    isChange,
    isFreightStageChange,
  }
}
