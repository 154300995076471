import AddOnDialog from './AddOnDialog.vue'
import { mountComponent } from './utils/index'

let instance

async function initInstance() {
  const { instance: _instance } = mountComponent(AddOnDialog)
  instance = _instance
}

export function showAddOnDialog(options = {}) {
  if (!window) return Promise.reject('this function can only be used in browser')
  
  return new Promise(() => {
    if (!instance) {
      initInstance()
    }
    instance.open(Object.assign({}, options))
  })
}

export function closeAddOnDialog() {
  instance?.close()
}

export function updateAddOndialogOpts(options) {
  instance?.updateOpts(options)
}
