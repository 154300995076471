/**
 * 获取凑单弹窗样式配置
 * @param {*} type 凑单弹窗类型
 * @returns 
 */

export function getStyleConfig(options) {
  const { addOnType, promoDetail } = options || {}
  const { IS_RW = false, PUBLIC_CDN = '', GB_cssRight } = gbCommonInfo || {}
  const isSheinClubGift = promoDetail?.type_id === 4 && promoDetail?.promotion_logo_type === 14
  const styleConfig = {
    'freeShipping': {
      '--header-bg': IS_RW ? `url("${PUBLIC_CDN}/she_dist/images/add-on/free_shipping_header_bg_rw-4358575e11.png") no-repeat center top/cover` : `url("${PUBLIC_CDN}/she_dist/images/add-on/free_shipping_header_bg-5964431039.png") no-repeat center top/cover`,
      '--header-right-bg': IS_RW ? (GB_cssRight ? 'url("https://img.ltwebstatic.com/images3_ccc/2024/04/12/fd/17129104266d651f035c518ef8e13af9b2e05930c6.png") no-repeat center top/cover' : `url("${PUBLIC_CDN}/she_dist/images/add-on/free_shipping_header_right_bg_rw-79a8f78352.png") no-repeat center top/cover`) : (GB_cssRight ? 'url("https://img.ltwebstatic.com/images3_ccc/2024/04/12/cd/17129103693f7e9b5393ffeb96d53cbebac6477c06.png") no-repeat center top/cover' : `url("${PUBLIC_CDN}/she_dist/images/add-on/free_shipping_header_right_bg-61b7bf4437.png") no-repeat center top/cover`),
      '--header-title-color': '#fff',
      '--header-count-down-label-color': '#000',
      '--header-count-down-color': '#fff',
      '--header-count-down-bg': '#000',
      '--footer-progress-tip-strong-color': IS_RW ? '#F8225D' : '#FF6D2A',
      '--footer-progress-inner-bg': 'linear-gradient(to right, #198055, #6ABD8C 58%)',
      '--footer-progress-inner-border': '0.5px solid #fff',
    },
    'accessory': {
      '--header-bg': isSheinClubGift ? `url("http://img.ltwebstatic.com/images3_acp/2024/09/24/8d/1727162647f501d37aad78d0a614fe26f7f9df2af3.png") no-repeat center top/cover` : `url("${PUBLIC_CDN}/she_dist/images/add-on/addon_accessory_header-3d97558811.png") no-repeat center top/cover`,
      '--header-right-bg': isSheinClubGift ? '' : `url("${PUBLIC_CDN}/she_dist/images/add-on/gift_header_right-27baeb9eb9.png") no-repeat center top/cover`,
    },
    'reduce': {
      '--header-bg': IS_RW ?
        `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-header-bg-rw-beca42839c.png")  no-repeat center top/cover` :
        `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-header-bg-1be5b408f0.png")  no-repeat center top/cover`,
      '--header-title-color': IS_RW ? '#fff' : '#461200',
    },
    'discount': {
      '--header-bg': IS_RW ?
      `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-header-bg-rw-beca42839c.png")  no-repeat center top/cover` :
      `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-header-bg-1be5b408f0.png")  no-repeat center top/cover`,
      '--header-title-color': IS_RW ? '#fff' : '#461200',
    },
    'bundle': {
      '--header-bg': IS_RW ?
      `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-header-bg-rw-beca42839c.png")  no-repeat center top/cover` :
      `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-header-bg-1be5b408f0.png")  no-repeat center top/cover`,
      '--header-title-color': IS_RW ? '#fff' : '#461200',
      '--header-right-bg': IS_RW ? `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-bundle-coin-2a2cf732e7.png") no-repeat center top/cover` : `url("${PUBLIC_CDN}/she_dist/images/add-on/promo-bundle-coin-2a2cf732e7.png") no-repeat center top/cover`,
    }
  }

  return styleConfig[addOnType] || {}
}

