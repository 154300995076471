<script setup>
import { provide, defineAsyncComponent } from 'vue'
import { useAddOnDialog } from './utils/index'
import { useRetainDialog } from 'public/src/pages/common/add-on-dialog/hooks/retain.js'
import useList from './hooks/useList'
import { SFeedback } from '@shein-aidc/sui-feedback'

const List = defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/List.vue'))
const Skeleton = defineAsyncComponent(() => import('./components/common/Skeleton.vue'))

const {
  state,
  open,
  close,
  showPopover,
  updateCart,
  updateBusinessCart,
  updateOpts,
  onClickItem,
  onAddBag,
} = useAddOnDialog()
const {
  loading,
  itemConfig,
  products,
  page,
  limit,
  total,
  saInfo,
  pageChange,
  reset,
} = useList(state)

const closeBeforeRetain = (payload) => {
  if (state.needShowRetainDialog) {
    const { open } = useRetainDialog()
    open()
    return 
  }
  close(payload)
}

const handleToPick = (isAuto = false) => {
  let status = ''
  const { promotion_id, type_id } = state?.promoDetail
  if ([4, 28].includes(+type_id)) {
    status = 'full-gift'
  }
  if ([2].includes(+type_id)) {
    status = 'buy-gift'
  }
  if ([22, 13].includes(+type_id)) {
    status = 'add-buy'
  }
  state.pickAddItemFn({
    appendageInfo: {
      status,
      promotionId: promotion_id,
      fromType: isAuto ? 'addItem-auto' : 'addItem'
    }
  })
}

provide('addOnDialogState', state)
provide('closeBeforeRetain', closeBeforeRetain) // 需要挽留弹窗的close
provide('close', close) // 直接close
provide('handleToPick', handleToPick)
provide('showPopover', showPopover)
provide('reset', reset)
provide('updateBusinessCart', updateBusinessCart)

defineExpose({
  open,
  close,
  updateOpts,
})

</script>

<template>
  <SDialog
    :visible="state.visible"
    show-close
    type="W996"
    class="add-on-dialog j-add-on-dialog"
    :style="state.styleConfig"
    @close-from-mask="close({ action: 'close' })"
    @close-from-icon="closeBeforeRetain({ action: 'close' })"
  >
    <template #title>
      <component :is="state.headerComponent" />
    </template>
    <template #subtitle>
      <component :is="state.subHeaderComponent" />
    </template>
    <div
      class="add-on-dialog__body"
      da-expose-code="addonV2ListExposeCode"
    >
      <Skeleton v-show="loading" />
      <List
        v-show="!loading && products.length"
        :item-config="itemConfig"
        :products="products"
        :language="state.language"
        :page="page"
        :limit="limit"
        :total="total"
        :is-click-to-detail="state.config.isClickToDetail"
        :type="state.addOnType"
        :sa="saInfo"
        :config="state.config"
        @page-change="pageChange"
        @update-cart="updateCart"
        @click-item="onClickItem"
        @add-bag="onAddBag"
      />
      <SFeedback
        v-show="!loading && !products.length"
        :content="state.language.SHEIN_KEY_PC_24972"
      >
        <template #footer>
          <SButton
            :type="['H28PX']"
            @click="reset"
          >
            {{ state.language.SHEIN_KEY_PC_22252 }}
          </SButton>
        </template>
      </SFeedback>
    </div>
    <template #footer>
      <component :is="state.footerComponent" />
    </template>
  </SDialog>
  <component :is="state.retainDialogComponent" />
</template>

<style lang="less" scoped>
.add-on-dialog {
  &__body {
    min-height: 50vh;
  }
  :deep(.sui-dialog__W996) {
    min-width: 996px;
  }
  :deep(.sui-dialog__title-wrap) {
    padding: 0;
  }
  :deep(.sui-dialog__title) {
    margin-bottom: 0;
  }
  :deep(.sui-dialog__closebtn) {
    top: 3.75px;
    right: 3.75px;
    width: 24px;
    height: 24px;
    padding: 6px;
    fill: #fff;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  :deep(.sui-dialog__footer) {
    padding: 0;
  }
  :deep(.sui-dialog__body) {
    padding: 0 40px;
  }
  :deep(.cart-addItem-list) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
  :deep(.sui-feedback) {
    padding-top: 10%;
    height: 450px;
  }
  /* stylelint-disable-next-line selector-max-specificity */
  :deep(.sui-feedback .sui-icons) {
    width: 96px;
  }
  :deep(.sui-feedback__content) {
    margin-bottom: 16px;
  }
}
</style>
