
import { reactive } from 'vue'

const retainDialogInfo = reactive({
  visible: false,
})

// 挽留弹窗
export function useRetainDialog() {
  const open = () => {
    toggle(true)
  }

  const close = () => {
    toggle(false)
  }

  const toggle = (show) => {
    retainDialogInfo.visible = show
  }

  return {
    retainDialogInfo,
    toggle,
    open,
    close,
  }
}
