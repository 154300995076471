import { abtservice } from '@/public/src/services/abt'
import schttp from '@/public/src/services/schttp'

const getAbtInfo = async () => {
  // 推荐/商卡 相关
  const cardNewKeys = [
    'listflashSale',
    'AllListStarReview',
    'listgoodsCard',
    'listquickship',
    'listquickshipKey',
  ]

  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtInfo = await abtservice.getUserAbtResult({ newPosKeys: cardNewKeys.join(',') })

  return abtInfo
}

const getAtomFields = (fields) => {
  const atomFields = {
    ...fields,
    estimatedPrice: false, // 凑谈弹窗不接入到手价
  }

  // 合规站点不展示销量标签
  if (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.IS_SUGGESTED) {
    Reflect.deleteProperty(atomFields, 'salesLabel')
  }

  return atomFields
}

export const getCardConfig = async () => {
  return schttp({
    url: '/api/productAtom/cardConfig/get',
    method: 'get',
    params: {
      newProductCard: true, // 是否新商卡 1  0
      column: 2, // 商卡一图几行  1 2 3
    }
  })
}

const getItemCongfig = (opts, cardConfig) => {
  const config = {
    ...cardConfig,
    // 凑单场景特殊参数
    daEventExposeId: '2-3-2',
    disableMainimgJump: true, // 禁止点击商品图片跳商项
    showAddToBoard: false, // 隐藏 加组弹窗
    showEstimatedPriceOnSale: false, // 不接入到手价
    showDeliveryWords: false, // 凑单弹窗不接入趋势
    ...opts,
  }

  // romwe 有 bug, 临时配置
  if (typeof window !== 'undefined' && gbCommonInfo.IS_RW) {
    config.showMainImgColor = true
  }
  return config
}

const getRecommendQuery = (abtInfo, fields) => {
  const {
    listflashSale,
    AllListStarReview,
    listgoodsCard,
    listquickship,
    listquickshipKey,
  } = abtInfo

  const abtInfoV3 = {
    newProductCard: true,
    listflashSale: listflashSale?.param?.listflashSale == 'flashSale',
    starReview: AllListStarReview?.param?.liststar,
    listQuickShip: listquickship?.param?.listquickship, // QuickShip标签优化
    listquickKey: listquickshipKey?.param?.listquickKey, // QuickShip标签优化
  }

  const atomFields = getAtomFields(fields)

  const atomCccParams = {
    cateId: '111',
    displayWindowAbtBranch: listgoodsCard?.param?.listGoods || ''
  }

  return {
    newPreHandle: true,
    abtInfoV3,
    atomFields,
    atomScene: {
      pageKey: 'page_add_cart',
      sceneKey: 'TWO_IN_A_ROW',
    },
    atomCccParams,
  }
}

export async function getProductConfig(param) {
  const [abtInfo, cardConfig] = await Promise.all([getAbtInfo(), getCardConfig()])
  const itemConfig = getItemCongfig(param || {}, cardConfig?.info?.cardConfig)
  const recommendQuery = getRecommendQuery(abtInfo, cardConfig?.info?.fields)

  return {
    itemConfig,
    recommendQuery,
  }
}
