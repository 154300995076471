import { reactive, computed, toRefs, nextTick, watch } from 'vue'
import { getInterfaceList } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/fetcher'
import { sleep } from '../utils/index'
import { analysis17413 } from '../utils/buryPointEvent'
import { isNewUserReturnCoupon } from '@/public/src/pages/cart_new/utils/index'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '2-3' }) // 商卡

const daEventExpose = daEventCenter.getExposeInstance()

export default function useList(options = {}) {
  const state = reactive({
    loading: false,
    products: [],
    abtInfo: {},
    page: 1,
    limit: 10,
    sort: 0,
    total: 0,
    queryParams: {},
    saInfo: {},
    isSatisfied: 0,
    itemConfig: {},
    recommendQuery: {},
    promoDetail: {},
    options: {},
    // storeInfo: {},
    // selectedFilterLabels: [],
  })
  const {
    loading,
    products,
    abtInfo,
    page,
    limit,
    sort,
    total,
    queryParams,
    saInfo,
    isSatisfied,
    itemConfig,
    recommendQuery,
    promoDetail,
    // storeInfo,
    // selectedFilterLabels,
  } = toRefs(state)

  const commonParams = computed(() => {
    const { addOnType, sceneId, cateIds, goodsIds, mallCode, quickship_prior, includeTspId, excludeTspId, goodsPrice } = queryParams.value

    return {
      goodsIds: [...new Set(goodsIds)].join(','), // 商品ID,  
      cateIds: [...new Set(cateIds)].join(','), // 类目ID
      jsonRuleId: JSON.stringify(abtInfo.value?.addnewhotJson?.param || {}), // 实验参数
      location: 'addnewhotJson',
      goods_price: goodsPrice ? (goodsPrice * 100).toFixed(0) : '', // 凑单差额价格(美分)
      mall_code: mallCode || '',
      scene_id: sceneId,
      add_on_type: addOnType,
      include_tsp_id: includeTspId,
      exclude_tsp_id: excludeTspId,
      quickship_prior,
    }
  })
  const fetchList = async (isFirst) => {
    try {
      loading.value = true
      await getList(isFirst)
    } finally {
      loading.value = false
      initExpose()
    }
  }
  const getList = async (isFirst, extraParams = {}) => {
    const { cateIds, goodsIds, goods_price, mall_code, add_on_type, include_tsp_id, exclude_tsp_id, quickship_prior } = commonParams.value
    const { adp, mainGoodsIds } = queryParams.value
    const contextParams = {
      cate_ids: cateIds,
      goods_ids: goodsIds,
      goods_price: goods_price,
      mall_code: mall_code,
      add_on_type: add_on_type,
      quickship_prior: quickship_prior,
      stock_enough: 1, // 是否有库存
      adp, // TR-27530支持外围传入指定商品id
      main_goods_id: [...new Set(mainGoodsIds)].slice(0, 20).join(','),
    }

    // 不能传空字符串
    if (include_tsp_id) contextParams.include_tsp_id = include_tsp_id
    if (exclude_tsp_id) contextParams.exclude_tsp_id = exclude_tsp_id

    const params = {
      ...commonParams.value,
      ...recommendQuery.value,
      pageEnable: 1,
      req_num: 300,
      pageNum: page.value, // 页数
      sort: sort.value, // 排序方式
      limit: limit.value, // 每页数量
      contextParams,
      ...extraParams,
    }
    products.value = []
    const { products: _products, total: _total, code } = await getInterfaceList(params, itemConfig.value).catch(() => null)
    // 填充不满10个时为数据能展示下空数据
    if (_products.length && _products.length < limit.value) {
      for (let i = 0; i < limit.value - _products.length; i++) {
        _products.push({})
      }
    }
    products.value = _products
    total.value = _total

    if (isFirst) {
      handleExpose({ _total, code })
    }
    return code
  }
  const initExpose = async () => {
    await sleep(300)
    daEventExpose && daEventExpose.subscribe({
      keycode: `addonV2ListExposeCode\`${
        itemConfig.value.daEventExposeId || ''
      }|2-10-2`
    })
  }
  const handleExpose = ({ code, total }) => {
    let service_error
    if (code == '0' && total == 0) {
      service_error = 'goods_is_empty'
    } else if (code != '0') {
      service_error = code ? code : 'recommend_unknown_error'
    }
    let data = {
      state: saInfo.value.state,
      service_error: service_error,
      is_satisfied: isSatisfied.value,
      parameter_detail: state.options,
      newuser_label: isNewUserReturnCoupon(promoDetail.value) ? 1 : 0,
    }
    if (saInfo.value.promotion_code !== '') {
      data['promotion_code'] = saInfo.value.promotion_code
    }
    analysis17413(data)
  }

  const pageChange = (page) => {
    state.page = page
    fetchList()

    scrollToTop()
  }
  const scrollToTop = (behavior = 'smooth') => {
    document.querySelector('.j-add-on-dialog')?.querySelector('.sui-dialog__body')?.scrollTo({
      top: 0,
      behavior,
    })
  }

  const reset = () => {
    resetParams()
    fetchList()
    scrollToTop()
  }
  const resetParams = () => {
    state.page = 1
    state.total = 0
    state.products = []
  }
  /*   const filter = async (params = {}) => {
    let code
    try {
      loading.value = true
      resetParams()
      const filterParams = {
        ...params
      }
      code = await getList(false, filterParams)
    } finally {
      loading.value = false
      return code
    }
  } */
  watch(() => options.visible, (val) => {
    if (val) {
      Object.assign(state, options)
      resetParams()
      fetchList(true)
      nextTick(() => {
        scrollToTop('auto')
      })
    }
  })

  return {
    loading,
    products,
    // selectedFilterLabels,
    page,
    limit,
    total,
    saInfo,
    itemConfig,
    pageChange,
    reset,
    // filter,
  }
}
