import { daEventCenter } from '@/public/src/services/eventCenter/index'
import { v4 as uuidv4 } from 'uuid'

daEventCenter.addSubscriber({ modulecode: '1-7-4' })

export const analysis17413 = (extraData) => {
  const data = JSON.parse(JSON.stringify(extraData))

  daEventCenter.triggerNotice({
    daId: '1-7-4-13',
    extraData: data
  })
}

// 关闭弹窗埋点
export const analysis17414 = (extraData) => {
  daEventCenter.triggerNotice({
    daId: '1-7-4-14',
    extraData
  })
}

export const analysis17410 = (extraData) => {
  const _eventId = uuidv4()
  
  daEventCenter.triggerNotice({
    daId: '1-7-4-10',
    extraData: {
      _scene: 3,
      _eventId,
      ...extraData
    }
  })
}

export const analysis17415 = (extraData) => {
  daEventCenter.triggerNotice({
    daId: '1-7-4-15',
    extraData
  })
}
