/**
 * 根据券档位获取券折扣信息
 * @param {object} rule 档位
 * @return {String} 返回折扣文案, 结果为: 带币种金额 或 打折百分比
 */
export function getCouponDiscount(rule) {
  if (!rule) return null
  return rule.value_amount ? rule.value_amount.amountWithSymbol : Number(rule.value) + '%'
}

/**
 * 根据券档位计算进度
 * 进度公式：(1 - 差额 / (当前门槛 - 上一档门槛)) * 100
 * @param {object} preRule 上一档位
 * @param {object} curRule 凑单档位
 * @return {Number} 
 */
export function getCouponProgress(curRule, preRule) {
  if (!curRule) return 0
  const preRuleMin = preRule?.min || 0
  return (1 - curRule.need_price?.amount / (Number(curRule.min) - Number(preRuleMin))) * 100
}

/**
 * 格式化coupon
 * @param {object} coupon 优惠券信息
 * @return {Object} _data 里的字段为客户端自处理数据
 */
export function formatCoupon(coupon) {
  if (!coupon?.coupon_code) return {}
  const { coupon_rule_list, next_range_id, status } = coupon
  const activeRule = coupon_rule_list?.find((rule) => rule.coupon_range_id == next_range_id)
  const activeRuleIndex = coupon_rule_list?.findIndex((r) => r.coupon_range_id == next_range_id)

  let is_satisfied, preRule
  const _status = Number(status)

  if (_status === 1) {
    // 未达到门槛
    is_satisfied = 0
    preRule = null
  } else if (_status === 2) {
    // 多档券部分满足
    is_satisfied = 2
    preRule = coupon_rule_list[activeRuleIndex - 1]
  } else if (_status === 3) {
    // 3-已全部满足;
    is_satisfied = 1
    preRule = coupon_rule_list[coupon_rule_list.length - 1]
  }

  return {
    ...coupon,
    _data: {
      activeRule, // 当前正在凑单的档位
      activeRuleIndex, // 当前正在凑当档位索引
      preRule, // 凑单档位的上一个档位
      is_satisfied, // 0:全部未满足; 1:全部满足; 2:部分满足
    },
  }
}

/**
 * 获取加车商品的页面来源（add_source）
 */
export function getAddSourceByType(type, saInfo) {
  let _type = ''
  switch (type) {
    case 'coupon':
      _type = 'coupon' // 优惠券
      break
    case 'freeShipping':
      _type = 'shipping' // 免邮活动
      break
    default: // 其他值都归为促销活动
      _type = 'promotion' // 促销活动
      break
  }
  return `cartadd\`${_type}\`${saInfo?.state || saInfo?.activity_from || 'cartadd'}`
}

/**
 * 处理优惠券排序
 * @param {String} primaryCoupon 主券码
 * @param {Array} secondaryCoupon 副券码
 * @param {Boolean} isSorted 是否已排序
 * @param {Array} couponListInfo 优惠券信息
 */
export function handleCouponSort({ primaryCoupon = '', secondaryCoupon = [], isSorted = false, couponListInfo = [] } = {}) {
  if (typeof primaryCoupon !== 'string') return console.error('主券码必须是一个字符')
  if (!Array.isArray(secondaryCoupon)) return console.error('副券码必须是一个数组')
  if (!primaryCoupon && !secondaryCoupon.length) return console.error('请传入主券码或者副券码')

  // 排序结果
  const sortRes = []

  // 优惠券Mapper
  const couponListMap = new Map(couponListInfo.map(coupon => [coupon.coupon_code, coupon]))

  // 主优惠券信息
  const primaryCouponInfo = couponListMap.get(primaryCoupon)

  // 副优惠券信息
  const secondaryCouponInfo = secondaryCoupon.map(code => couponListMap.get(code)).filter(info => info)

  // 处理副券码排序
  const handleSecondaryCouponSort = (data, isSorted) => {
    // 外部排序,直接返回
    if (isSorted) return data

    // 全部未满足
    const notSatisfied = []
    // 部分满足
    const partiallySatisfied = []
    // 全部满足
    const allSatisfy = []

    data.forEach(coupon => {
      const { is_satisfied } = coupon?._data
      if (is_satisfied === 1) allSatisfy.push(coupon)
      else if (is_satisfied === 0) notSatisfied.push(coupon)
      else if (is_satisfied === 2) partiallySatisfied.push(coupon)
    })

    // 获取当前凑单档位
    const getActiveRule = coupon => coupon?._data.activeRule || coupon?._data.preRule

    // 按差额升序
    const sortByNeedAmount = (a, b) => getActiveRule(a).need_price.amount - getActiveRule(b).need_price.amount
    // 按门槛降序
    const sortByMinAmount = (a, b) => getActiveRule(b).min_amount.amount - getActiveRule(a).min_amount.amount

    // 按全部未满足、部分满足、全部满足进行排序
    // 内部再按特定策略排序
    return [
      ...notSatisfied.sort(sortByNeedAmount),
      ...partiallySatisfied.sort(sortByNeedAmount),
      ...allSatisfy.sort(sortByMinAmount),
    ]
  }
  
  // 优先主券
  if (primaryCouponInfo) sortRes.push(primaryCouponInfo)
  if (secondaryCouponInfo.length) sortRes.push(...handleSecondaryCouponSort(secondaryCouponInfo, isSorted))

  return sortRes
}

/**
 * 返回最新的优惠券（基于已排序好的优惠券的顺序返回）
 * @param {Array} couponListInfo 当前最新的优惠券(接口返回)
 * @param {Array} prevCouponListInfo 上次接口返回的优惠券
 * @returns
 */
export function handleCouponFilter({ couponListInfo = [], prevCouponListInfo = [] } = {}) {
  const couponListMap = new Map(couponListInfo.map(coupon => [coupon.coupon_code, coupon]))
  return prevCouponListInfo.filter(couponInfo => couponListMap.get(couponInfo.coupon_code)) // 在老数据中，过滤掉新数据中没有的
    .map(m => couponListMap.get(m.coupon_code)) // 替换成新数据
}
